<template>
  <div class="container">
    <navbar></navbar>
    <div class="content py-20 px-15">
      <p class="bold fs-xxl t-center m-b-20">GameFi {{ $t("home.news") }}</p>
      <!-- tab切换 -->
      <div class="tabbox borders d-flex ai-center jc-between">
        <div
          class="tab_item t-center fs-xxl"
          v-for="(item, index) in tabList"
          :key="index"
          @click="tabChange(index)"
          :class="tabIndex == index ? 'tab_item_active' : ''"
        >
          {{ item.text }}
        </div>
      </div>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="$t('public.pulling')"
        :loosing-text="$t('public.loosing')"
        :loading-text="$t('public.loading')"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.nomores')"
          :loading-text="$t('public.loading')"
          :offset="100"
          @load="onLoad"
        >
          <van-skeleton
            title
            avatar
            :row="1"
            :loading="skeletoning"
            avatar-size="50px"
            class="m-t-10"
          >
            <van-card
              :thumb="item.cover_remote_url"
              v-for="(item, index) in list"
              :key="index"
              @click="toDetail(item)"
            >
              <template #title>
                <p class="fs-lg bold">
                  {{ lang == "zh" ? item.title : item.title_en }}
                </p>
              </template>
              <template #desc>
                <p class="fs-lg t-color2 m-t-10 lh-3 word">
                  {{
                    lang == "zh" ? item.content : item.content_en | getPlainText
                  }}
                </p>
              </template>
            </van-card>
          </van-skeleton>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,
      list: [],

      tabIndex: 0,
      skeletoning: true,
    };
  },
  computed: {
    tabList() {
      return [
        { index: 0, text: this.$t("news.latest") }, //最新NFT
        { index: 1, text: this.$t("news.facts") }, //行业趣闻
      ];
    },
    lang() {
      return this.$storage.get("lang");
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    tabChange(index) {
      this.tabIndex = index;
      this.finished = false;
      this.loading = true;
      this.pageNumber = 1;
      this.totalPage = 1;
      this.list = []
      this.getList();
    },
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      setTimeout(() => {
        this.finished = false;
        this.getList();
        this.pageNumber++;
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getList();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList();
      }, 1000);
    },
    getList() {
      let that = this;
      this.loading = true;
      // 1:最新nft
      this.$axios
        .get(this.$api.newslist + "/" + (this.tabIndex + 1), {
          page: this.pageNumber,
          page_size: 8,
        })
        .then((res) => {
          if (res.code == 200) {
            that.$toast.clear();
            that.loading = false;
            that.refreshing = false;
            let arr = res.data.list;
            that.totalPage = res.data.totalPage; // 总条数
            if (arr == null || arr.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            that.list.push(...arr);
            // 如果当前页数>=总条数，不再触发滚动加载
            if (that.pageNumber >= that.totalPage) {
              that.finished = true;
            }
            this.skeletoning = false;
          } else {
            this.loading = false;
            that.$toast(res.msg);
            this.skeletoning = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //资讯详情
    toDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: { id: item.id, type: this.tabIndex + 1 },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-card {
  background-color: transparent;
  color: #fff;
  padding: 15px 0;
  border-bottom: 1px solid rgba($color: #9998c2, $alpha: 0.3);
}
/deep/ .van-card__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/deep/ .van-card__header {
  align-items: center;
}
/deep/ .van-card__thumb {
  width: 120px;
  height: 80px;
}
.tabbox {
  min-width: 300px;
  max-width: 350px;
  height: 32px;
  border-image: linear-gradient(#0d182a, #0d182a),
    linear-gradient(57deg, #15a3ee, #391aeb);
  margin: 0 auto;
  .tab_item {
    width: 50%;
  }
  .tab_item_active {
    background: #074ce7;
    border-radius: 10px;
    line-height: 32px;
  }
}
</style>